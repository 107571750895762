body {
  font-family: sans-serif;
}
a {
  color: #369;
}
.note {
  width: 500px;
  margin: 50px auto;
  font-size: 1.1em;
  color: #333;
  text-align: justify;
}
#drop-area {
  border: 2px dashed #ccc;
  border-radius: 20px;
  width: 50%;
  margin: 1% auto;
  padding: 1%;
}
#drop-area.highlight {
  border-color: cornflowerblue;
}

.my-form {
  margin-bottom: 10px;
}

.button {
  display: inline-block;
  padding: 10px;
  background: #ccc;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.button:hover {
  background: #ddd;
}
progress {
  margin-left: 15%;
}
.hidePB {
  display: none;
}
