* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
}
html {
  font-size: 12pt;
  line-height: 1.4;
  font-weight: 600;
  font-family: "Fredericka the Great", cursive;
}
body {
  margin: 0;
}
ul {
  list-style-type: none;
  margin: 0 auto 1%;
  padding: 0;
  width: 90%;
}

.App button {
  width: 100px;
  height: 15%;
  max-height: 50px;
  margin: 1% auto;
  font-size: 16px;
  display: block;
}
/* button.submitBtn {
  --widthA: 50px;
  margin-right: calc(52.63% - var(--widthA));
} */
button.purchase-button {
  width: 200px;
}

button:hover {
  cursor: pointer;
  box-shadow: 0 10px 6px -6px #777;
}
button:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
  border-color: -moz-use-text-color #ffffff #ffffff -moz-use-text-color;
  border-style: none solid solid none;
  border-width: medium 1px 1px medium;
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
  transform: translateY(4px);
}

label {
  text-decoration: underline;
  font-size: 1.2rem;
}
select {
  margin: 3%;
  font-size: 1.5rem;
}

a {
  color: black;
  text-decoration: none;
}
a:visited {
  color: black;
}
nav a {
  font-size: 1.4rem;
  color: cornflowerblue;
  text-decoration: none;
}
nav a:visited {
  color: cornflowerblue;
}
nav a:hover {
  color: navy;
}
h1 {
  font-size: 1.6em;
  border-bottom: 1px solid black;
  margin-bottom: 2%;
}
h1,
h2 {
  display: inline;
}
div > h2 {
  font-size: 1.3em;
}
h3 {
  font-size: 1.2rem;
}
p {
  font-size: 1.2rem;
}

nav {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 50%,
    rgba(211, 233, 233, 1) 100%
  );
  margin: 0 auto;
}
.page-nav {
  width: 100%;
  margin-bottom: 1%;
}

.mobile {
  display: inherit;
}
.desk {
  display: none;
}

.description {
  width: 80%;
  margin: 0 auto;
}
.card {
  border-top: solid 1px black;
  padding: 2% 0;
}
.card:hover {
  cursor: pointer;
}
.hidden {
  display: none;
}

.art-image {
  max-height: 50vh;
  max-width: 90%;
  width: auto;
  border: 3px solid #333333;
  border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
}
.art-image::before {
  content: "";
  border: 2px solid #353535;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.5deg);
  border-radius: 1% 1% 2% 4% / 2% 6% 5% 4%;
}

.bio-input {
  width: 80%;
  height: 100px;
}
.bio-container {
  overflow-wrap: break-word;
}
.edit-art-form {
  text-align: left;
  padding: 0 5%;
  width: 100%;
}
.edit-art-form > input {
  text-align: left;
  font-size: 1.5rem;
  width: 90%;
  display: block;
}
.dimLabel {
  margin-left: 5%;
  display: block;
}
.edit-art-form > input.dimInput {
  max-width: 150px;
  display: inline;
  margin-right: 0;
}
.priceLabel {
  display: block;
  margin-top: 2%;
}
.priceSpan {
  display: inline;
}
.edit-art-form > input.priceInput {
  display: inline;
  margin-left: 0;
  max-width: 150px;
}
.matchFont {
  font-size: 1.2rem;
  margin: 0;
  text-decoration: underline;
}

.sign-in-message {
  width: 60%;
}
.page-message {
  width: 80%;
}

.matchText {
  font-size: 20px;
}

.App {
  text-align: center;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 2% 0 1%;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url("./desertTowers.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.App > * {
  margin: 0 auto;
}

.App > section {
  width: 80%;
  margin: 0 auto;
}

.label-container {
  border: solid 1px #ccc;
  margin: 50px auto 8px;
  padding: 5px 8px;
  position: relative;
  width: 300px;
}

.label-container input {
  border: none;
  font-size: 24px;
  outline: 0;
  padding: 30px 20px 5px;
  width: fit-content;
  margin: 0 auto;
}

.label-container label {
  font-size: 16px;
  position: absolute;
  transform-origin: top left;
  transition: all 0.1s ease-in-out;
}

.label-container input #mealName {
  width: 10%;
  margin: 0 auto;
}

.block-message {
  font-size: 2rem;
  margin: 20%;
}

button > a {
  text-decoration: none;
  color: black;
}

button > a:visited {
  color: black;
}
.longList {
  width: 60%;
}
.mediumList {
  width: 80%;
}
.welcomeText {
  display: block;
}

div.error {
  width: 80%;
  margin: 0 auto;
}
.welcomeText {
  width: 80%;
  margin: 0 auto;
}

.label-container {
  margin: 30px auto 8px;
  padding: 5px 8px;
  width: 200px;
}
.label-container input {
  font-size: 16px;
  width: 180px;
}
.label-container label {
  font-size: 12px;
}
.page-nav label {
  display: inline-block;
  font-style: normal;
  font-size: 2em;
  padding: 10px;
  text-decoration: none;
}
.page-nav label:hover {
  cursor: pointer;
}

.page-nav ul li {
  display: block;
}

.page-nav ul,
#hamburger {
  display: none;
}
.page-nav input:checked ~ ul {
  display: block;
}
.main-header {
  width: 90%;
  margin: 2% auto;
}
#bio-section {
  font-size: 1.3em;
  padding: 10% 0;
}
#fileElem {
  font-size: 8pt;
  cursor: pointer;
}

@media screen and (min-width: 420px) {
  h1 {
    font-size: 2em;
  }
  div > h2 {
    font-size: 1.5em;
  }
  #fileElem {
    font-size: 10pt;
  }
}
@media screen and (min-width: 720px) {
  #bio-section {
    font-size: 1.4em;
    width: 70%;
  }
  .edit-art-form {
    padding-left: 20%;
  }
  .edit-art-form > input {
    width: 75%;
  }
  ul {
    width: 100%;
  }
  .App button {
    min-width: 150px;
    display: inline;
  }
  button.cancel-btn {
    margin-left: 2%;
  }
  button.cancel-btn-auth {
    margin-left: 0;
  }
  button.submitBtn {
    --widthB: 75px;
    margin-right: calc(60% - var(--widthB));
    display: block;
  }

  .main-header {
    width: 70%;
  }
  #height,
  #width {
    margin-left: 50px;
  }
  #fileElem {
    font-size: 12pt;
  }
}

@media screen and (min-width: 810px) {
  #bio-section {
    font-size: 1.5em;
    width: 60%;
  }
  .App button {
    min-width: 200px;
  }

  button.submitBtn {
    --widthC: 100px;
    margin-right: calc(60% - var(--widthC));
  }
  .mobile {
    display: none;
  }
  .desk {
    display: inherit;
  }

  h1.title {
    width: 90%;
  }

  .page-nav label,
  #hamburger {
    display: none;
  }

  .page-nav ul {
    display: inline;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .page-nav ul li {
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 1070px) {
  .art-image {
    max-width: 60%;
  }
  #bio-section {
    font-size: 1.6em;
    width: 60%;
  }
  ul li {
    margin: 0 1vw;
  }

  .art-div {
    padding-top: 5%;
    width: 25%;
  }
  .art-div-right {
    float: right;
    margin: 0 7%;
  }
  .art-div-left {
    float: left;
    margin: 0 7%;
  }
  #fileElem {
    font-size: 13pt;
  }
}
